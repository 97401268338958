<script>
const DEFAULT_SCALE_DELTA = 1.1;
const MIN_SCALE = 0.4;
const MAX_SCALE = 3;

import '@/assets/css/ckeditor/list-styling.css';

export default {
  name: 'HrbrCkeditorHtmlViewer',

  props: {
    htmlData: {
      type: String,
      default: '',
    },
    source: {
      type: String,
      default: 'default',
    },
    showRedlineButtons: {
      type: Boolean,
      default: false,
    },
    suggestButtonText: {
      type: String,
      default: '',
    },
    editButtonText: {
      type: String,
      default: '',
    }
  },

  data() {
    return {
      scale: 1,
      html: this.htmlData,
      isContentVisible: false,
      downloadButtonText: 'Download (docx)',
    };
  },

  computed: {
    isZoomInDisabled() {
      return this.scale >= MAX_SCALE;
    },

    isZoomOutDisabled() {
      return this.scale <= MIN_SCALE;
    },

    contentStyle() {
      return {
        transform: `scale(${this.scale})`,
      };
    },

    mainClasses() {
      return ['hrbr-ckeditor-html-viewer', `hrbr-ckeditor-html-viewer--${this.source}`];
    },
  },

  methods: {
    zoomIn() {
      let newScale = this.scale;
      newScale = (newScale * DEFAULT_SCALE_DELTA).toFixed(2);
      newScale = Math.ceil(newScale * 10) / 10;
      newScale = Math.min(MAX_SCALE, newScale);
      this.scale = newScale;
    },

    zoomOut() {
      let newScale = this.scale;
      newScale = (newScale / DEFAULT_SCALE_DELTA).toFixed(2);
      newScale = Math.floor(newScale * 10) / 10;
      newScale = Math.max(MIN_SCALE, newScale);
      this.scale = newScale;
    },

    fitWidth() {
      const { document, content } = this.$refs;
      const { width } = content.getBoundingClientRect();
      const documentWidth = document.clientWidth;
      const scale = parseFloat((documentWidth / width).toFixed(2));
      if (scale >= MIN_SCALE && scale <= MAX_SCALE) {
        this.scale = scale;
      }
    },

    prepareHtml() {
      const wrapper = document.createElement('div');
      wrapper.innerHTML = this.html;

      const links = wrapper.querySelectorAll('a');
      links.forEach(this.processHtmlLink);

      const html = wrapper.innerHTML;
      wrapper.remove();
      this.html = html;
    },

    processHtmlLink(link) {
      const { anchorid } = link.dataset;
      if (anchorid) return;

      const href = link.getAttribute('href');
      if (href?.startsWith('http')) {
        link.target = '_blank';
      }
    },

    showContent() {
      this.isContentVisible = true;
    },

    downloadButtonClickHandler() {
      this.$emit('download-click');
      this.downloadButtonText = 'working...';

      const timeout = 5000;
      setTimeout(() => {
        this.downloadButtonText = 'Done';
      }, timeout);

      setTimeout(() => {
        this.downloadButtonText = 'Download (docx)';
      }, timeout + 1000);
    },

    suggestButtonClickHandler() {
      this.$emit('suggest-click');
    },

    updateHtmlData(htmlData) {
      this.html = htmlData;
    },
  },

  created() {
    this.$parent.$on('update-html-data', this.updateHtmlData);
  },

  mounted() {
    this.prepareHtml();

    setTimeout(() => {
      this.fitWidth();
      this.showContent();
    }, 250); // timeout to get correct sizes
  },
};
</script>

<template>
  <div :class="mainClasses">
    <div
      class="hrbr-ckeditor-html-viewer__header"
      :style="{ justifyContent: showRedlineButtons ? 'space-between' : 'center' }">
      <div class="hrbr-ckeditor-html-viewer__header-zoom-btns">
        <div
          class="hrbr-ckeditor-html-viewer__zoom-btn hrbr-ckeditor-html-viewer__zoom-btn--out"
          :class="{ 'hrbr-ckeditor-html-viewer__zoom-btn--disabled': isZoomOutDisabled }"
          @click="zoomOut">
          <i class="fa-light fa-circle-minus"></i>
        </div>
        <div
          class="hrbr-ckeditor-html-viewer__zoom-btn hrbr-ckeditor-html-viewer__zoom-btn--in"
          :class="{ 'ckeditor-html-view__zoom-btn--disabled': isZoomInDisabled }"
          @click="zoomIn">
          <i class="fa-light fa-circle-plus"></i>
        </div>
      </div>

      <div class="signer-redlining__yes-no-ctn" v-if="showRedlineButtons">
        <!-- suggest button -->
        <b-button
          v-if="suggestButtonText"
          class="signer-redlining-btn"
          icon-left="pen"
          icon-pack="fal"
          @click="suggestButtonClickHandler"
          >{{ suggestButtonText }}</b-button
        >

        <!-- Edit document button -->
        <b-button
          v-if="editButtonText"
          class="signer-redlining-btn"
          icon-left="file-arrow-down"
          icon-pack="fal"
          @click="$emit('edit-document')"
        >{{ editButtonText }}</b-button
        >

        <!-- download button -->
        <b-button
          class="signer-redlining-btn"
          icon-left="arrow-down-to-bracket"
          icon-pack="fal"
          @click="downloadButtonClickHandler"
          >{{ downloadButtonText }}</b-button
        >
      </div>
    </div>

    <div class="hrbr-ckeditor-html-viewer__main">
      <div class="hrbr-ckeditor-html-viewer__document" ref="document">
        <div class="hrbr-ckeditor-html-viewer__document-wrapper">
          <div class="hrbr-ckeditor-html-viewer__content-container">
            <div
              class="hrbr-ckeditor-html-viewer__content"
              :class="{ 'hrbr-ckeditor-html-viewer__content--visible': isContentVisible }"
              :style="contentStyle"
              ref="content"
              v-html="html"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-ckeditor-html-viewer {
  --header-height: 36px;

  font-family: sans-serif;
  color: #000;
  width: 100%;
  height: 100%;
  position: relative;

  &__header {
    display: flex;
    align-items: center;
    height: var(--header-height);
    padding: 5px 10px;
    background: #fff;
    box-shadow: rgb(0 0 0 / 10%) 0px -1px 0px 0px inset;

    .signer-redlining__yes-no-ctn {
      margin-top: 0;
    }
  }

  &__header-zoom-btns {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__zoom-btn {
    font-size: 18px;
    color: #363636;
    cursor: pointer;
    transition: all 0.2s;

    &--active,
    &:hover {
      color: var(--hrbr-primary-color-active);
    }

    &--disabled {
      color: #848484;
      pointer-events: none;
    }

    i {
      display: block;
    }
  }

  &__main {
    position: absolute;
    top: var(--header-height);
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__document {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    overflow: auto;
    background: #fff;
  }

  &__document-wrapper {
    width: 100%;
    height: auto;
  }

  &__content-container {
    max-height: 0;
    overflow: visible;
  }

  &__content {
    min-width: 800px;
    padding: 38px 75px 75px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    transform-origin: 0 0;
  }

  &__content--visible {
    opacity: 1;
    visibility: visible;
  }
}

@media all and (max-width: 768px) {
  .hrbr-ckeditor-html-viewer {
    &__content {
      padding: 30px;
    }
  }
}
</style>

<style lang="postcss">
.hrbr-ckeditor-html-viewer-modal .modal-content {
  height: 100vh;
}

.hrbr-ckeditor-html-viewer--agreelink,
.hrbr-ckeditor-html-viewer--library {
  .annotation.document-owner-annotation {
    font-weight: 400;
    border: none;
  }
}

/*
Formatting styles.
Keep global or use deep selectors if you want to move them to scoped style.
https://vuejs.org/api/sfc-css-features.html#scoped-css
*/
.hrbr-ckeditor-html-viewer__content {
  line-height: 1.15;
  
  .annotation {
    height: 20px;
    overflow-y: clip;
    margin-left: 0;
    margin-right: 0;
  }

  .annotation.for-current-user {
    background-color: #2d71ad4f;
    border: solid 2px #2d71adb7;
  }

  .annotation.document-owner-annotation {
    background: none;
    color: black;
    /* font-weight: 700; */
  }

  .annotation[data-itemfieldtype='SIGNATUREINPUT'] {
    background: none;
    border: none;
  }

  .annotation[data-itemfieldtype='SIGNATUREINPUT'] img {
    display: inline;
    width: auto;
    height: auto;
    max-height: 25px;
    vertical-align: middle;
  }

  .table .annotation[data-itemfieldtype='SIGNATUREINPUT'] img {
    max-width: 100%;
  }

  .annotation[data-itemfieldtype='HTMLINPUT'] {
    display: inline-block;
    height: auto;
  }

  .annotation[data-itemfieldtype='URLTEXTINPUT'] a {
    color: inherit;
  }

  .annotation.document-owner-annotation[data-itemfieldtype='SIGNATUREINPUT'] {
    border-bottom: solid 1px black;
  }

  .annotation.document-owner-annotation[data-itemfieldtype='SIGNATUREINPUT'] .annotation-text {
    visibility: hidden;
  }

  /* no indent for first annotation */
  p .annotation:first-of-type {
    margin-left: 0;
  }

  p {
    margin: 0;
    padding: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 100%;
    font-weight: 400;
  }

  strong {
    color: inherit;
    font-weight: 700;
  }

  ul,
  ol {
    padding: revert;
  }

  ol {
    list-style-type: decimal;
  }

  ol ol {
    list-style-type: lower-latin;
  }

  ol ol ol {
    list-style-type: lower-roman;
  }

  ol ol ol ol {
    list-style-type: upper-latin;
  }

  ol ol ol ol ol {
    list-style-type: upper-roman;
  }

  ul {
    list-style-type: circle;
  }

  ul ul {
    list-style-type: disc;
  }

  ul ul ul {
    list-style-type: square;
  }

  ul ul ul ul {
    list-style-type: square;
  }

  /*
   * Images
   */
  /* @ckeditor/ckeditor5-image/theme/imageresize.css */
  img.image_resized {
    height: auto;
  }
  /* @ckeditor/ckeditor5-image/theme/imageresize.css */
  .image.image_resized {
    max-width: 100%;
    display: block;
    box-sizing: border-box;
  }
  /* @ckeditor/ckeditor5-image/theme/imageresize.css */
  .image.image_resized img {
    width: 100%;
  }
  /* @ckeditor/ckeditor5-image/theme/imageresize.css */
  .image.image_resized > figcaption {
    display: block;
  }
  /* @ckeditor/ckeditor5-image/theme/image.css */
  .image {
    display: table;
    clear: both;
    text-align: center;
    margin: 0.9em auto;
    min-width: 50px;
  }
  /* @ckeditor/ckeditor5-image/theme/image.css */
  .image img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    min-width: 100%;
    height: auto;
  }
  /* @ckeditor/ckeditor5-image/theme/image.css */
  .image-inline {
    /*
     * Normally, the .image-inline would have "display: inline-block" and "img { width: 100% }" (to follow the wrapper while resizing).;
     * Unfortunately, together with "srcset", it gets automatically stretched up to the width of the editing root.
     * This strange behavior does not happen with inline-flex.
     */
    display: inline-flex;
    max-width: 100%;
    align-items: flex-start;
  }
  /* @ckeditor/ckeditor5-image/theme/image.css */
  .image-inline picture {
    display: flex;
  }
  /* @ckeditor/ckeditor5-image/theme/image.css */
  .image-inline picture,
  .image-inline img {
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 100%;
  }

  /*
   * Tables
   */
  /* @ckeditor/ckeditor5-table/theme/tablecolumnresize.css */
  .table .ck-table-resized {
    table-layout: fixed;
  }
  /* @ckeditor/ckeditor5-table/theme/tablecolumnresize.css */
  .table table {
    overflow: hidden;
  }
  /* @ckeditor/ckeditor5-table/theme/tablecolumnresize.css */
  .table td,
  .table th {
    /* overflow-wrap: break-word; */
    overflow-wrap: anywhere;
    position: relative;
  }
  /* @ckeditor/ckeditor5-table/theme/tablecaption.css */
  .table > figcaption {
    display: table-caption;
    caption-side: top;
    word-break: break-word;
    text-align: center;
    color: var(--ck-color-selector-caption-text);
    background-color: var(--ck-color-selector-caption-background);
    padding: .6em;
    font-size: .75em;
    outline-offset: -1px;
  }
  /* @ckeditor/ckeditor5-table/theme/table.css */
  .table {
    margin: 0.9em auto;
    display: table;
  }
  /* @ckeditor/ckeditor5-table/theme/table.css */
  .table table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    height: 100%;
    border: 1px double hsl(0, 0%, 70%);
  }
  /* @ckeditor/ckeditor5-table/theme/table.css */
  .table table td,
  .table table th {
    min-width: 2em;
    padding: .4em;
    border: 1px solid hsl(0, 0%, 75%);
  }
  /* @ckeditor/ckeditor5-table/theme/table.css */
  .table table th {
    font-weight: bold;
    background: hsla(0, 0%, 0%, 5%);
  }
  /* @ckeditor/ckeditor5-table/theme/table.css */
  [dir="rtl"] .table th {
    text-align: right;
  }
  /* @ckeditor/ckeditor5-table/theme/table.css */
  [dir="ltr"] .table th {
    text-align: left;
  }
  /* not from the docs */
  .table table {
    border-color: black !important;
  }
  .table td {
    border-color: black !important;
  }
  .table[style*='float:left'],
  .table[style*='float: left'] {
    margin-right: 10px;
  }
  .table[style*='float:right'],
  .table[style*='float: right'] {
    margin-left: 10px;
  }

  /*
   * Marker sizes
   */
  [data-marker-size="9"]::marker { font-size: 9px; }
  [data-marker-size="10"]::marker { font-size: 10px; }
  [data-marker-size="11"]::marker { font-size: 11px; }
  [data-marker-size="12"]::marker { font-size: 12px; }
  [data-marker-size="13"]::marker { font-size: 13px; }
  [data-marker-size="14"]::marker { font-size: 14px; }
  [data-marker-size="15"]::marker { font-size: 14px; }
  [data-marker-size="16"]::marker { font-size: 16px; }
  [data-marker-size="17"]::marker { font-size: 17px; }
  [data-marker-size="18"]::marker { font-size: 18px; }
  [data-marker-size="19"]::marker { font-size: 19px; }
  [data-marker-size="20"]::marker { font-size: 20px; }
  [data-marker-size="21"]::marker { font-size: 21px; }
  [data-marker-size="22"]::marker { font-size: 22px; }
  [data-marker-size="23"]::marker { font-size: 23px; }
  [data-marker-size="24"]::marker { font-size: 24px; }
  [data-marker-size="25"]::marker { font-size: 25px; }
  [data-marker-size="26"]::marker { font-size: 26px; }
  [data-marker-size="27"]::marker { font-size: 27px; }
  [data-marker-size="28"]::marker { font-size: 28px; }
  [data-marker-size="29"]::marker { font-size: 29px; }
  [data-marker-size="30"]::marker { font-size: 30px; }
  [data-marker-size="31"]::marker { font-size: 31px; }
  [data-marker-size="32"]::marker { font-size: 32px; }

  [data-pagination-page]::before {
    content: '';
    display: block;
    width: 100%;
    border-bottom: 2px dashed #c4c4c4;
    margin: 20px 0;
  }

  /* ckeditor anchors */
  a.hrbr-highlight {
    color: initial;
    cursor: initial;
  }
}
</style>
