import HrbrCkeditorHtmlViewer from '../components/Ckeditor/HrbrCkeditorHtmlViewer.vue';
import { ModalProgrammatic as Modal } from 'buefy';

function openCkeditorHtmlViewer(options = {}) {
  const { props = {}, events = {} } = options;

  const modal = Modal.open({
    component: HrbrCkeditorHtmlViewer,
    parent: props.parent || this,
    canCancel: true,
    customClass: 'hrbr-ckeditor-html-viewer-modal',
    props,
    events,
  });

  return modal;
}

export default {
  install(Vue) {
    Vue.prototype.$openCkeditorHtmlViewer = openCkeditorHtmlViewer;
  },
};
